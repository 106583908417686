@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');

body {
  margin: 0;
  background-color: white;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong, b {
  font-family: 'Barlow', sans-serif; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: 'Barlow', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}
